import { twMerge } from "tailwind-merge";
import { cn } from "~/utils/helper";

export default function TitleAndHeader({
  title,
  header,
  footer,
  alignHeader = false,
  className,
  headerClassName,
  titleClassName,
  footerClassName,
}: {
  title: string | JSX.Element;
  header?: string;
  footer?: string | JSX.Element;
  alignHeader?: boolean; // align: true = left, false = center
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  footerClassName?: string;
}) {
  return (
    <div className={twMerge(`inline-block `, className)}>
      {header && (
        <p
          className={cn(
            `text-2xl font-bold text-db-550 max-lg:text-xl ${!alignHeader && "text-center"}`,
            headerClassName,
          )}
        >
          {header}
        </p>
      )}
      <h3
        className={cn(
          `mt-3 text-[32px] font-bold text-Secondary-Dark-Blue-db-600 max-lg:mt-2 max-lg:text-2xl ${!alignHeader && "text-center"}`,
          titleClassName,
        )}
      >
        {title}
      </h3>
      {footer && (
        <p
          className={cn(
            "mt-4 text-justify text-lg text-Secondary-Dark-Blue-db-300",
            {
              "text-center": !alignHeader,
            },
            footerClassName,
          )}
        >
          {footer}
        </p>
      )}
    </div>
  );
}
